import request from "../utils/request";

export function get_store_account_list(data: JSON){
    return request({
        url: "/getStoreAccountList",
        method: "post",
        data: data
    });
}

export function get_store_account_detail(data: JSON){
    return request({
        url: "/getStoreAccountDetail",
        method: "post",
        data: data
    });
}

export function save_store_account_detail(data: JSON){
    return request({
        url: "/saveStoreAccountDetail",
        method: "post",
        data: data
    });
}